.h6 {
  margin-bottom: 15px;
  display: block;
  font-weight: bold;
  font-family: "GothamProRegular", sans-serif;
  color: #000;
  font-size: 18px;
}

.p {
  font-size: 16px;
  font-family: "GothamProLight", sans-serif;
  margin-bottom: 0;
  text-align: justify;

  a{
    text-decoration: none;
    color: inherit;
  }

}