#mainMenu {
  z-index: 1;
}

#filter {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, .55);
  width: 100%;
  height: 100%;
}


.mainTitle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-transform: uppercase;
    font-size: 64px;
    color: #FFF;
    text-align: center;
  }

}


#startImage {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.newImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0;
  transition: opacity 2.5s ease-in-out;

  &.loading {
    opacity: 1;
  }
}

