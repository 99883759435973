.gg-container{
  //min-height: 60vh;
  padding-bottom: 60px;
}

.thumb img {
  -webkit-filter: grayscale(0);
  filter: none;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
}

.thumb img:hover {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.thumb {
  padding: 5px;
}

img.gallery-item {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

a.gallery {
  position: relative;
  display: block;

  .fa {
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    right: 15px;
    top: 15px;
    color: #000;
  }
}