.album-navigation {
  overflow: hidden;

  &__container {
    display: grid;
    position: relative;
    left: -50px;
    transition: left .5s ease-in-out;
    //transform: translateX(-50px);
  }

  &__item {
    width: 100%;
    height: 125px !important;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .3s ease-in-out;

    &.is_current {
      border: 1px solid #000;
      filter: grayscale(1);
    }

    &:hover {
      filter: grayscale(1);
    }
  }
}