.sub-controls {
  //margin-bottom: 30px;

  button {
    display: block;
    float: none;
    width: auto;
  }

  form {
    width: 100%;
  }

  .subs {
    display: flex;
    border-bottom: 1px solid #ededed;
    flex-wrap: wrap;

    & > a {
      padding: 0 10px;
      max-width: calc(100% / 8);
      box-sizing: border-box;
    }
  }

  .sub-item {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding-bottom: 10px;
    position: relative;

    img {
      display: block;
      margin: 0 auto 10px;
      max-width: 100%;
    }

    .sub-img-counter {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.1;
    }
  }

  .add-sub-button .fa {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #337ab7;
    font-size: 36px;
    cursor: pointer;
  }

}