.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    position: sticky;
    top: 0;
}

.breadcrumb > li {
    display: inline !important;
}

.breadcrumb-item {
    position: relative;
    z-index: 3;
}

.breadcrumb > li + li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}

.breadcrumb>.active {
    color: #777;
}