.admin-gallery-item {
  transition: width .5s ease-in-out;
  cursor: pointer;

  &.active {
    cursor: default;
    text-align: left;
    font-size: 18px;
    padding: 50px 0;
    width: 100%;
  }

  .image-redactor {
    position: relative;

    .close-image-redactor {
      position: absolute;
      right: -25px;
      top: 0;
      cursor: pointer;
      font-size: 18px;
    }
  }
}