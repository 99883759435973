.album, .image {
  display: inline-block;
  width: 200px;
  height: 130px;
  //margin: 0 5px 10px;
  margin: 0 3px;
  cursor: pointer;
  background-size: cover !important;
  position: relative;
  box-sizing: border-box;

  .display-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    color: #FFF;
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
  }

  strong {
    font-size: 16px;
    margin-bottom: 5px;
  }

  &:hover .display-flex {
    opacity: 0;
  }
}

.images-view {
  .image {
    .filter {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
    }
  }
}